import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import '../Cards/Cards.scss'
import CategoryPageLayout from '../CategoryPageLayout/CategoryPageLayout'
// import { CategoryFeatureProps } from '../CategoryFeatures/CategoryFeature'
import { CategoryCardProps } from '../GamesCategories/CategoryCard'
import GameCard from '../GameCard/GameCard'
// import { Breadcrumb } from '../../Breadcrumbs/Breadcrumbs'
import TriviaInfo from '../CategoryAndGamesInfo/TriviaInfo'
import DesktopLarge from '../GameCard/DesktopLarge'
import useShowAll from '../../../hooks/useShowAll'
import { triviaFaqs } from '../../FAQs/faqs-list'
import { GKGURU, ONE23, PICME } from '../../internal-links'
import useTestimonials from '../../Testimonials/useTestimonials'
import skills from '../game-skills'
import gameDescription from '../games-description'

const Trivia: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "trivia-intro.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      f1: file(relativePath: { eq: "casual-feature1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 585, layout: CONSTRAINED)
        }
      }
      f2: file(relativePath: { eq: "casual-feature2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 585, layout: CONSTRAINED)
        }
      }
      f3: file(relativePath: { eq: "casual-feature3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      casual: file(relativePath: { eq: "casual.png" }) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      cards: file(relativePath: { eq: "cards.png" }) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      PicMe: file(
        relativePath: { eq: "Mobile Poster Assets/PicMe_Horizontal Copy 3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      GK: file(
        relativePath: {
          eq: "Mobile Poster Assets/GK_Guru_Horizontal_12 Copy 3.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      One23: file(relativePath: { eq: "Mobile Poster Assets/iPoster.png" }) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      GKL: file(
        relativePath: { eq: "Desktop poster assets/GK_Guru_Horizontal.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      One23L: file(
        relativePath: { eq: "Desktop poster assets/Horizontal_Poster.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      PicMeL: file(
        relativePath: {
          eq: "Desktop poster assets/PicMe_Horizontal Coppy 2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      thPicme: file(
        relativePath: { eq: "thumbnails/PicMe_iPoster_Poster.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 312, layout: CONSTRAINED)
        }
      }
      th123: file(relativePath: { eq: "thumbnails/iPoster.png" }) {
        childImageSharp {
          gatsbyImageData(width: 312, layout: CONSTRAINED)
        }
      }
      thgk: file(relativePath: { eq: "thumbnails/GK_Guru_iPoster.png" }) {
        childImageSharp {
          gatsbyImageData(width: 312, layout: CONSTRAINED)
        }
      }
    }
  `)

  // const features: CategoryFeatureProps[] = [
  //   {
  //     title: 'Win with topics like maths, geography & logos',
  //     imgSrc: data.f1.childImageSharp.fluid,
  //     numberColor: '#37D6C2',
  //   },
  //   {
  //     title: 'Play never-seen-before games like Hide & Seek',
  //     imgSrc: data.f2.childImageSharp.fluid,
  //     numberColor: '#FF3D3C',
  //   },
  //   {
  //     title: 'Get thousands of exciting questions in each topic',
  //     imgSrc: data.f3.childImageSharp.fluid,
  //     numberColor: '#2270DA',
  //   },
  // ]

  const otherCategories: CategoryCardProps[] = [
    {
      category: 'casual',
      imgSrc: data.casual.childImageSharp.gatsbyImageData,
      description: 'Cool games like Pool & Carrom',
    },
    {
      category: 'cards',
      imgSrc: data.cards.childImageSharp.gatsbyImageData,
      description: 'From small blinds to high stakes',
    },
  ]

  // const breadcrumbs: Breadcrumb[] = [
  //   {
  //     title: 'All Games',
  //     url: '/games',
  //   },
  //   {
  //     title: 'Trivia',
  //     url: '',
  //   },
  // ]

  const isDesktop = useShowAll()
  const { triviaTestimonials } = useTestimonials()

  return (
    <div id="cards-category">
      <CategoryPageLayout
        pageTitle="Answer fun questions from movies, cricket, maths, etc."
        // features={features}
        // featuresTitle="Choose your best topic and top the leaderboard"
        otherCategories={otherCategories}
        // breadcrumbs={breadcrumbs}
        introThumbnail={data.intro.childImageSharp.gatsbyImageData}
        livePlayersText={
          <>
            Players playing Trivia on <span className="underline">Mega</span>{' '}
            right now!
          </>
        }
        CategoryInfo={<TriviaInfo />}
        testimonials={triviaTestimonials.slice(0, 3)}
        faqs={triviaFaqs}
      >
        <div className="m-card">
          <GameCard
            category="trivia"
            showCategory
            name="GK"
            showName
            description={gameDescription.GK.long}
            showDescription
            gameImage={data.GK.childImageSharp.gatsbyImageData}
            gameUrl={GKGURU}
          />
        </div>
        <div className="m-card">
          <GameCard
            category="trivia"
            showCategory
            name="123"
            showName
            description={gameDescription[123].long}
            showDescription
            gameImage={data.One23.childImageSharp.gatsbyImageData}
            gameUrl={ONE23}
          />
        </div>
        <div className="m-card">
          <GameCard
            category="trivia"
            showCategory
            name="PicMe"
            showName
            description={gameDescription.PicMe.long}
            showDescription
            gameImage={data.PicMe.childImageSharp.gatsbyImageData}
            gameUrl={PICME}
          />
        </div>

        {isDesktop ? (
          <div className="d-cards">
            <div className="d-card d-card-full">
              <DesktopLarge
                category="trivia"
                showCategory
                name="GK"
                showName
                description={gameDescription.GK.long}
                showDescription
                detailsBackground="#86369D"
                gameImage={data.GKL.childImageSharp.gatsbyImageData}
                gameUrl={GKGURU}
                gameThumbnail={data.thgk.childImageSharp.gatsbyImageData}
                skills={skills.GK}
              />
            </div>
            <div className="d-card d-card-full mt-36">
              <DesktopLarge
                category="trivia"
                showCategory
                name="PicMe"
                showName
                description={gameDescription.PicMe.long}
                showDescription
                detailsBackground="#0E606C"
                gameImage={data.PicMeL.childImageSharp.gatsbyImageData}
                gameUrl={PICME}
                gameThumbnail={data.thPicme.childImageSharp.gatsbyImageData}
                skills={skills.PicMe}
              />
            </div>
            <div className="d-card d-card-full mt-36">
              <DesktopLarge
                category="trivia"
                showCategory
                name="123"
                showName
                description={gameDescription[123].long}
                showDescription
                detailsBackground="#C96038"
                gameImage={data.One23L.childImageSharp.gatsbyImageData}
                gameUrl={ONE23}
                gameThumbnail={data.th123.childImageSharp.gatsbyImageData}
                skills={skills['123']}
              />
            </div>
          </div>
        ) : null}
      </CategoryPageLayout>
    </div>
  )
}

export default Trivia

import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TriviaCategory from '../../components/Games/Trivia/Trivia'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { GAMES, TRIVIA } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { TRIVIA as TRIVIA_APK } from '../../components/download-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Trivia',
    url: TRIVIA,
  },
]

const Trivia: React.FC = () => {
  const [triviaFaqsSt, setTriviaFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ triviaFaqs }) =>
      setTriviaFaqsSt(triviaFaqs)
    )
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={TRIVIA_APK}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Quiz & Trivia Games: Earn Money by Playing Quiz Upto 1 Cr | Mega"
        description="Answer the fastest. Win exciting cash prizes. Discover the joy of Trivia with the thrill of real money. Download Mega to play 123, GK & more real money quiz games."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(triviaFaqsSt)}
      />
      <TriviaCategory />
    </Layout>
  )
}

export default Trivia

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { isAndroid, isIOS } from '../../../formatForBigQuery'
import useRandomNumber from '../../../hooks/useRandomNumber'
import LinkButton from '../../LinkButton/LinkButton'
import PopupDownload from '../../PopupDownload/PopupDownload'
import Title from '../../Title/Title'
import './LivePlayersCount.scss'

interface Props {
  text: string | React.ReactNode
  showButton?: boolean
  gameName?: string
  apkLink?: string
  pokerOneLink?: Record<'android' | 'ios' | 'web', string>
  rummyOneLink?: Record<'android' | 'ios' | 'web', string>
}

const LivePlayersCount: React.FC<Props> = ({
  text,
  gameName,
  showButton = false,
  apkLink,
  pokerOneLink,
  rummyOneLink,
}) => {
  const data = useStaticQuery(graphql`
    {
      players: file(relativePath: { eq: "live-players.png" }) {
        childImageSharp {
          gatsbyImageData(width: 344, layout: CONSTRAINED)
        }
      }
    }
  `)

  const count = useRandomNumber(4000, 6000)

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const popupCode = (
    <>
      {isPopupOpen ? (
        <PopupDownload
          close={() => {
            setIsPopupOpen(false)
          }}
          gameName={gameName || 'Poker'}
          apkLink={apkLink}
          pokerLink={pokerOneLink}
          rummyLink={rummyOneLink}
          showPokerOnly={gameName?.toLowerCase() === 'poker'}
          showRummyOnly={gameName?.toLowerCase() === 'rummy'}
        />
      ) : null}
    </>
  )

  const [platform, setPlatform] = useState('web') // default set to web

  useEffect(() => {
    if (isAndroid()) {
      setPlatform('android')
    }
    if (isIOS()) {
      setPlatform('ios')
    }
  }, [platform])

  const popupDirectLink = (device: any) => {
    switch (device) {
      case 'ios':
        return (
          (gameName?.toLowerCase() === 'rummy'
            ? rummyOneLink?.ios
            : pokerOneLink?.ios) || '#'
        )
      case 'web':
        return (
          (gameName?.toLowerCase() === 'rummy'
            ? rummyOneLink?.web
            : pokerOneLink?.web) || '#'
        )
      default:
        return (
          (gameName?.toLowerCase() === 'rummy'
            ? rummyOneLink?.android
            : pokerOneLink?.android) || '#'
        )
    }
  }

  const handlePlay = () => {
    gameName?.toLowerCase() === 'rummy' || gameName?.toLowerCase() === 'poker'
      ? (window.location.href = popupDirectLink(platform))
      : setIsPopupOpen(true)
  }

  return (
    <div id="LivePlayersCount">
      <Title color="#000">{text}</Title>
      <p color="#37D6C2" className="count">
        +
        {count
          .toFixed(2)
          .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,')
          .replace('.00', '')}
      </p>
      <div className="stripes-wrap">
        <GatsbyImage
          image={data.players.childImageSharp.gatsbyImageData}
          alt="live players"
          imgStyle={{ objectFit: 'contain' }}
          className="image"
        />
        <div className="stripes" />
      </div>

      {showButton ? (
        <div className="popBtn-wrap">
          {isAndroid() ? (
            <>
              <LinkButton onClick={handlePlay} className="popBtn">
                Play Instantly
              </LinkButton>
              {popupCode}
            </>
          ) : (
            <>
              {gameName === 'Rummy' ? (
                <LinkButton
                  target="_blank"
                  to={popupDirectLink(platform)}
                  className="popBtn"
                >
                  Play Instantly
                </LinkButton>
              ) : (
                <LinkButton onClick={handlePlay} className="popBtn">
                  Play Now
                </LinkButton>
              )}
            </>
          )}
        </div>
      ) : null}
      {popupCode}
    </div>
  )
}

export default LivePlayersCount

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import './CategoryCard.scss'

export interface CategoryCardProps {
  category: string
  imgSrc: FluidObject
  description: string
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  category,
  imgSrc,
  description,
}) => {
  return (
    <a href={`/${category.toLowerCase()}/`}>
      <div className="category-card">
        <div className="category-image-div">
          <GatsbyImage
            image={imgSrc}
            alt={category}
            className="category-image"
          />
        </div>
        <div className="category">{category}</div>
        <div className="desc">{description}</div>
      </div>
    </a>
  )
}

export default CategoryCard

import React from 'react'
import './CategoryPageLayout.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'
import GamesIntro from '../GamesIntro/GamesIntro'
import CategoryCard, {
  CategoryCardProps,
} from '../GamesCategories/CategoryCard'
import HomeTestimonials from '../../Index/Testimonials/HomeTestimonials'
import HomeFAQs from '../../Index/HomeFAQs/HomeFAQs'
import LivePlayersCount from '../LivePlayersCount/LivePlayersCount'
import { TestimonialProps } from '../../Testimonials/Testimonial'
import { Faq } from '../../FAQs/faqs-list-interface'
import InfoCard from '../CategoryAndGamesInfo/InfoCard'

interface Props {
  pageTitle: string
  otherCategories: CategoryCardProps[]
  introThumbnail: typeof GatsbyImage
  livePlayersText: string | React.ReactNode
  CategoryInfo: React.ReactNode
  testimonials: TestimonialProps[]
  faqs?: Faq[]
  children: React.ReactNode
}

const CategoryPageLayout: React.FC<Props> = ({
  pageTitle,
  otherCategories,
  introThumbnail,
  children,
  livePlayersText,
  CategoryInfo,
  testimonials,
  faqs,
}) => {
  return (
    <div id="category-page-layout">
      <GamesIntro thumbnail={introThumbnail} addImageMask disableOverlay />

      <div className="page-content">
        <Container>
          <h1>{pageTitle}</h1>
        </Container>

        <Container className="games-grid">{children}</Container>

        <Container className="info-card">
          <InfoCard>{CategoryInfo}</InfoCard>
        </Container>

        <div className="white-bg">
          <Container>
            <LivePlayersCount text={livePlayersText} />
          </Container>
        </div>

        <HomeTestimonials color="#000" testimonials={testimonials} />
        <div className="faqs-wrap">
          <HomeFAQs faqs={faqs} />
        </div>

        <div className="other-categories">
          <h2 className="text">Explore other game categories</h2>
          <div className="cards">
            {otherCategories.map(c => {
              return (
                <div key={c.category} className="cat-card-wrap">
                  <CategoryCard
                    category={c.category}
                    imgSrc={c.imgSrc}
                    description={c.description}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryPageLayout

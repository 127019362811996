import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const TriviaInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Trivia on Mega</h2>
      <p>
        Remember screaming the right answers to the TV screen, while the
        contestants kept thinking? Did you dream of earning money by playing a
        quiz on the hotseat? And did you congratulate yourself with your
        imaginary wins?
      </p>
      <p>
        You no longer have to imagine winning, when you can actually do so in
        reality! With the internet in every pocket, you can enter live trivia
        contests or play online quiz games for real money. Here’s the golden
        opportunity to put your brain to work, all with a few taps on your
        phone. Answer right and finish the fight.
      </p>
      <p>
        Online Trivia &amp; quiz for money games cover a variety of genres —
        complex topics, general knowledge, speedy calculations or simply
        identifying logos. Other online apps let you play quizzes to win money -
        but only once a day, for a fixed period of time, with the prizes
        distributed amongst any number of participants. That doesn’t sound fair!
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Worry no more! No tricks, just Trivia, only on Mega. Experience the best
        Trivia &amp; quiz games for real money online. Compete 24x7 in real time
        with 100% verified profiles. No bots, no waiting, no division of prizes.
        Whether you are a math buff, have an eye for detail, or are simply full
        of hidden wisdom, there is definitely something for you on Mega.
      </p>
      <p>Download Mega on your Android device now.</p>
      <p>
        Experience the joy of learning, the rush of defeating real players, and
        the thrill of playing quizzes and winning real money rewards. Deal?
        Choose your favourite genre from a wide variety of Trivia and quiz
        games. Pick a table. Beat the clock and beat your opponents. Sweep away
        the prize with your Trivia skills, and withdraw your winnings instantly.
      </p>

      <p>
        No knowledge is useless, no effort is ever wasted. Find the most
        exciting quiz games online, with real money rewards, only at Mega.
      </p>

      <h3>123</h3>
      <p>
        Still remember BODMAS? Can you divide quickly to multiply your winnings?
        Do you like adding fun to your life, and subtracting the problems? If
        yes, then use your skills to pay your bills. Compete with other players
        to answer fun math problems in real time. With system-generated
        questions and 100% verified profiles, 123 is a game of true mathematical
        skills. Answer fast to be the first. Be right and win the fight. Ready
        to play exciting multiplayer math games and earn real money?
      </p>

      <h3>PicMe</h3>
      <p>
        Can you identify bats from the birds without blinking an eye? Do you
        possess the talent of identifying logos in seconds? If your eyes are
        sharp and your reflexes strong, then get ready to play PicMe, an
        exciting picture quiz. Compete with 100% verified players in real time,
        to answer picture-based questions. Count the objects fast, or quickly
        name the logo. Answer faster than others, and win real cash real quick.
      </p>

      <h3>GK</h3>
      <p>
        Wondering what to do with all those unique, interesting facts buzzing in
        your brain? Put your curious brain to the right use, and get rewarded at
        Mega GK. Success in life might be about being at the right place at the
        right time. But success in Mega GK is always about answering it right
        within the right time. Compete with 100% verified players in real-time
        to answer General Knowledge questions and win real money. Can you answer
        before others hit the buzzer?
      </p>
    </>
  )

  return (
    <div className="category-info">
      <ReadMoreLessSections visible={visible} hidden={hidden} />
    </div>
  )
}

export default TriviaInfo
